import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticatedGuard } from "src/guards/authenticated.guard";

const routes: Routes = [
  {
    path: "main",
    loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
    canActivate: [AuthenticatedGuard],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "**", redirectTo: "main", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
