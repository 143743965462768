import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "src/shared/services/auth.service";
import { NotificationService } from "src/shared/services/notification.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticatedGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.getToken() !== null) {
      const expiredAt = this.authService.getExpireAt();
      const currentTime = new Date().getTime();
      if (Number(expiredAt) > currentTime) {
        return true;
      } else {
        this.notificationService.showErrorMessage("Session Expired!");
        this.router.navigateByUrl("auth/login");
        return false;
      }
    } else {
      this.notificationService.showErrorMessage("Login to use the system!");
      this.router.navigateByUrl("auth/login");
      return false;
    }
  }
}
