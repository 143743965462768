import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  BASE_URL = environment.url;
  constructor(private http: HttpClient, private router: Router) {}

  public login(email: string, password: string) {
    const body = {
      email: email,
      password: password,
      platform: "Web",
    };
    return this.http.post(`${this.BASE_URL}auth`, body);
  }

  public changePassword(body: any) {
    return this.http.patch(
      `${
        this.BASE_URL
      }users/${this.getUserID()}/change_pwd?token=${this.getToken()}`,
      body
    );
  }

  public logout() {
    localStorage.clear();
    this.router.navigateByUrl("auth/login");
  }

  // set block
  public setUserID(userID: string) {
    localStorage.setItem("user_id", userID);
  }

  public setIsDefaultPassword(isDefault: string) {
    localStorage.setItem("is_default_password", isDefault);
  }

  public setMerchantID(merchantID: string) {
    localStorage.setItem("merchant_id", merchantID);
  }

  public setMerchantLogo(merchantLogo: string) {
    localStorage.setItem("merchant_logo", merchantLogo);
  }

  public setMerchantName(merchantName: string) {
    localStorage.setItem("merchant_name", merchantName);
  }

  public setMerchantType(merchantType: string) {
    localStorage.setItem("merchant_type", merchantType);
  }

  public setUsername(username: string) {
    localStorage.setItem("username", username);
  }

  public setExpireAt(expire: number) {
    localStorage.setItem("expire_at", expire.toString());
  }

  public setRole(role: string) {
    localStorage.setItem("role", role);
  }

  public setToken(token: string) {
    localStorage.setItem("token", token);
  }

  // get block
  public getUserID() {
    return localStorage.getItem("user_id");
  }

  public getIsDefaultPassword() {
    return localStorage.getItem("is_default_password");
  }

  public getMerchantID() {
    return localStorage.getItem("merchant_id");
  }

  public getMerchantLogo() {
    return localStorage.getItem("merchant_logo");
  }

  public getMerchantName() {
    return localStorage.getItem("merchant_name");
  }

  public getMerchantType() {
    return localStorage.getItem("merchant_type");
  }

  public getUsername() {
    return localStorage.getItem("username");
  }

  public getExpireAt() {
    return localStorage.getItem("expire_at");
  }

  public getRole() {
    if (
      localStorage.getItem("role") === "Merchant" &&
      localStorage.getItem("merchant_type") === "tour"
    ) {
      return "Tour Merchant";
    }
    return localStorage.getItem("role");
  }

  public getToken() {
    return localStorage.getItem("token");
  }
}
