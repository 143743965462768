import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  public showSuccessMessage(message: any = "Operation Success!") {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["success-snackbar"],
    });
  }

  public showErrorMessage(message: any = "Operation Error!") {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["error-snackbar"],
    });
  }

  public showRecommendMessage(message: any) {
    this.snackBar.open(message, null, {
      duration: 2000,
      panelClass: ["recommend-snackbar"],
    });
  }
}
