// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --staging` replaces `environment.ts` with `environment.staging.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://tvn-stg-api.myoptistech.com/v1/",
  url_v2: "https://tvn-stg-api.myoptistech.com/v2/",
  ws_url: "https://tvn-stg-chat-api.myoptistech.com/",
  tinymce_api_key: "l4r9e340kvcv5oup4we5t769mvqjhf0tg56k84gy0q0oy334"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
